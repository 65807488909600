import { get, post, put } from "./APIBase";
import {
  AUTH_URL, CHANGE_PASSWORD_URL, DASHBOARD_URL, EMAIL_SEND_URL, EMAIL_URL, INVOICE_COUNT_URL, INVOICE_LIST_URL, INVOICE_URL,
  OWNER_URL, USER_URL, CLIENT_URL
} from "./APIInterface";

export const loginAPI = async (data) => {
  return await post(AUTH_URL, data);
}
export const getUser = async (data) => {
  return await get(USER_URL, data);
}
export const cteateUser = async (data) => {
  return await post(USER_URL, data);
}
export const getUserById = async ({ id, ...data }) => {
  return await get(`${USER_URL}/${id}`, data);
}

export const updateUserById = async (data) => {
  return await put(`${USER_URL}/${data.id}`, data);
}

export const getAllUsers = async (data) => {
  return await get(`${USER_URL}/list`, data);
}

export const getAllClients = async (data) => {
  return await get(`${CLIENT_URL}/list`, data);
}

export const saveClient = async (data) => {
  return await post(`${CLIENT_URL}`, data);
}

export const getClientById = async ({ id, ...data }) => {
  return await get(`${CLIENT_URL}/${id}`, data);
}

export const getDashboard = async () => {
  return await get(DASHBOARD_URL);
}



export const emailSetup = async (data) => {
  return await post(EMAIL_URL, data);
}
export const ownerSetup = async (data) => {
  return await post(OWNER_URL, data);
}
export const invoiceSetup = async (data) => {
  return await post(INVOICE_URL, data);
}
export const sendInvoice = async (data) => {
  return await post(EMAIL_SEND_URL, data);
}
export const getSetup = async () => {
  return await get(INVOICE_URL);
}
export const getInvoice = async () => {
  return await get(INVOICE_COUNT_URL);
}
export const getInvoiceList = async ({ page = 0, size = 20 }) => {
  return await get(`${INVOICE_LIST_URL}?page=${page}&size=${size}`);
}
export const getInvoiceById = async (id) => {
  return await get(`${INVOICE_URL}/${id}`);
}

export const changepassword = async (data) => {
  return await post(CHANGE_PASSWORD_URL, data);
}