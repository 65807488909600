import { Box, useTheme } from "@mui/material";


const LogoText = ({ height }) => {
  const theme = useTheme();
  const color = { fill: theme.palette.mode === 'dark' ? '#fff' : '#000' };
  return (
    <Box component={'svg'} version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 297" height={height}>
      <g >
        <Box component='path' sx={color} d="m109.5 38.5c10.6 2.2 20.3 7.5 26.6 14.3c11.9 12.8 16.8 30.6 16.9 60.4v10.8h-21.5h-21.5v-12.9c0-26-3.2-31.4-18.3-31.3c-7.9 0-10.8 1.5-14.2 7.3c-1.9 3.3-2 5.2-2 64.4c0 67.1-0.1 66.1 6.1 70.2c4.1 2.6 17.8 2.6 21.7-0.1c5.3-3.6 6.1-6.5 6.5-25.4l0.4-17.2h21.4h21.4v10.7c-0.1 42.7-10.8 64.1-36.6 73.4c-8.3 3-33 3.9-42.4 1.5c-24.6-6.2-37.5-23.1-41-53.6c-1.3-11.3-1.3-107 0-118.5c2.1-18.3 6.7-29.8 15.7-39.5c6.4-6.9 16-12.2 26.3-14.4c8.6-1.9 25.8-1.9 34.5-0.1z" />
        <Box component='path' sx={color} d="m256.8 38.6c16.6 3.4 28.2 12.2 35.2 26.8c7.5 15.7 7.5 16.1 7.5 87.6c0 62.4-0.1 63.6-2.2 71.3c-6.4 22.5-18.9 35.6-38.5 40.2c-8.3 2-29.3 1.9-37.6 0c-19.4-4.6-31.8-17-38.3-38.5c-2.3-7.4-2.3-8.3-2.7-70.6c-0.3-59.5-0.2-63.7 1.7-72.7c4.9-24.4 18.7-39.5 40.1-44.1c8.9-2 25.4-1.9 34.8 0zm-29.3 44.4c-4.8 4.5-5 7.4-5 70v58.5l2.5 5c3.1 6.1 7.2 7.9 16.7 7.2c6.8-0.5 10.3-2.2 13-6.4c1.7-2.5 1.8-7.3 2-63.3c0.2-55 0.1-61-1.5-65.5c-1.2-3.5-2.6-5.4-4.7-6.6c-6.3-3.5-18.8-2.9-23 1.1z" />
        <Box component='path' sx={color} d="m698.8 38.6c21 4.3 34.6 18 40.3 40.5c1.6 6.3 2.2 12.9 2.6 26.6l0.6 18.3h-21.7h-21.6v-15.3c-0.1-24.3-2.7-28.8-16.8-28.9c-9-0.1-12.1 1.5-15.2 7.7l-2.5 5l-0.3 54.3c-0.4 65.4 0.2 71.4 7.4 75.2c4 2.2 17.2 2 20.7-0.3c5.7-3.7 6.2-5.6 6.5-24.9l0.4-17.8h21.5h21.6l-0.6 18.7c-0.6 21-2.2 29.7-7.9 41.3c-6 12.4-18.6 22.1-33 25.5c-8.3 2-29.3 1.9-37.6 0c-21.2-5-34.3-19.7-39.3-43.9c-1.9-9.4-2-12.9-1.7-72.5c0.4-69.6 0.3-67.7 7.5-82.9c6.4-13.6 18.9-23.3 34.3-26.6c8.9-2 25.4-1.9 34.8 0z" />
        <Box component='path' sx={color} d="m327 39.9l37.8 0.3l37.7 0.3l7.9 2.8c17 6 26.8 16.2 32.5 33.7c3.8 11.9 4.1 18 4.1 75.5c0 51.5-0.2 56.8-2 65.4c-4.9 23.8-17.3 37.7-38.6 43.1c-7 1.8-11.1 2-43.6 2h-35.8zm54.5 43.1h-11.5v138.2l12.5-0.4c14.2-0.4 16.5-1.4 20-8.4c1.9-3.8 2-5.9 2-60.4c0-56.2 0-56.5-2.2-61.3c-1.7-3.5-3.1-5.1-5.7-6.2c-2.4-1-7.2-1.5-15.1-1.5z" />
        <Box component='path' sx={color} d="m474 40h60.5h60.5v21.5v21.5h-39h-39v23.5v23.5h27.5h27.5v21.5v21.5h-27.5h-27.5v24v24h39h39v21v21h-60.5h-60.5v-111.5z" />
        <Box component='path' sx={color} d="m769 40h34.3c25 0.1 36.3 0.5 42.2 1.5c20.9 3.7 35 17.2 41.1 39c2.2 7.9 3.1 34.8 1.5 44c-1.8 10-4.7 16.9-9.6 22.3l-4.5 5l3.4 3.6c1.9 1.9 4.6 5.9 6 8.9c5 10.2 5.6 15.8 5.6 58.9v39.8h-41.9l-0.3-40.2c-0.3-44.7-0.4-44.8-6.8-48c-2.8-1.4-6.1-1.8-15.7-1.8h-12.3v90h-43zm57 43.2l-14-0.4v47.2h12.8c19.2 0 21-1.6 21.9-18.6c0.7-13.5-0.7-21.9-4.2-25.4c-2.4-2.4-3.1-2.5-16.5-2.8z" />
        <Box component='path' sx={color} d="m916 40h60.5h60.5v21.5v21.5h-39h-39v23.5v23.5h28h28v21.5v21.5h-28h-28v24v24h39h39v21v21h-60.5h-60.5v-111.5z" />
        <Box component='path' sx={color} d="m1058.9 40h21h21l0.3 85.7l0.3 85.8l2.5 5c3.1 6.1 7.2 7.9 16.7 7.2c6.8-0.5 10.3-2.2 13-6.4c1.7-2.5 1.8-8.6 2.1-90l0.2-87.3h21.5h21.4l0.3 85.7l0.3 85.8l2.5 5c3.1 6.1 7.2 7.9 16.7 7.2c7.2-0.5 10.5-2.3 13-7c1.7-3 1.8-9.1 2.1-90l0.2-86.7h21.6h21.5l-0.4 87.2c-0.4 98-0.1 94.6-7.8 110.6c-6.8 14.1-17.7 22.7-33.8 26.8c-4.7 1.2-9.7 1.5-19 1.2c-11.3-0.3-13.2-0.6-18.6-3.1c-6.6-3.1-12-7.8-16.7-14.5l-3-4.4l-3.4 5c-4.1 6.1-9.2 10.4-16.4 14c-4.9 2.3-6.9 2.7-18 3c-19.9 0.6-31.9-3.2-42.9-13.4c-6.6-6.1-11.8-15-15.3-26.4l-2.2-7.5l-0.4-89.3z" />
        <Box component='path' sx={color} d="m1282 40h61c33.6 0 61 0.4 61 0.9c0 0.4-13.5 41-30 90.1c-16.5 49.1-30 89.4-30 89.6c0 0.2 13.1 0.4 29 0.4h29v21v21h-61c-34.7 0-61-0.4-61-0.9c0-0.5 13.5-40.8 30-89.7c16.5-48.9 30-89 30-89.1c0-0.2-13-0.3-29-0.3h-29v-21.5z" />
      </g>
    </Box>
  )
}

export default LogoText;