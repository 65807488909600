import { Avatar, Box, Card, Container, Icon, Slide, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import Page from "../components/Page";
import ApplicationContext from "../context";
import { getDashboard } from "../context/APIRequest";

const options = [
  { key: 'sales', icon: 'attach_money', title: 'Total Sales', backgroundColor: 'success.main' },
  { key: 'client', icon: 'person_add', title: 'Total Clients', backgroundColor: 'info.main' },
  { key: 'called', icon: 'call_end', title: 'Total Calls', backgroundColor: 'error.main' },
  { key: 'leads', icon: 'call', title: 'New Leads', backgroundColor: 'success.dark' },
  { key: 'users', icon: 'person', title: 'Total Users', backgroundColor: 'primary.main' },
]
const Dashboard = ({ children, ...props }) => {
  const { auth, setLoading, callAPI } = useContext(ApplicationContext);
  const loadingRef = useRef(setLoading);
  const callRef = useRef(callAPI);
  const [starting, setStarting] = useState(true);
  const [data, setData] = useState({});
  useEffect(() => {
    loadingRef.current(true)
    setTimeout(() => {
      setStarting(false);
      loadingRef.current(false)
    }, 1000);
    const getData = async () => {
      const dashboard = localStorage.getItem('dashboard')
      if (dashboard) {
        setData(JSON.parse(dashboard))
      }
      const [result, error] = await callRef.current(getDashboard);
      if (!error) {
        setData(result);
        localStorage.setItem('dashboard', JSON.stringify(result))
      }
    }
    if (auth) {
      getData();
    }
  }, [auth])

  return <Page title={'Dashboard'} flexGrow={1} mt={3}>
    <Container maxWidth="xl">
      <Box gap={2} display={'grid'} gridTemplateColumns={'repeat(auto-fill, minmax(250px,1fr)) '} pb={5}>
        {
          !starting && options.map(({ key, icon, title, backgroundColor }, index) => {
            return (
              <Slide in direction="up" key={`card-${key}`} {...props} style={{ transitionDelay: `${50 * index}ms` }}>
                <Card>
                  <Stack padding={4} direction='row'>
                    <Box flexGrow={1}>
                      <Avatar sx={{ height: 60, width: 60, backgroundColor }} >
                        <Icon sx={{ color: 'primary.contrastText', fontSize: '33px !important' }}>{icon}</Icon>
                      </Avatar>
                    </Box>
                    <Stack flexGrow={1}  >
                      <Typography textAlign={'right'}>{title}</Typography>
                      <Typography textAlign={'right'} fontSize={24} fontWeight={600}>{data?.[key]}</Typography>
                    </Stack>
                  </Stack>
                </Card>
              </Slide>
            )
          })
        }
      </Box>
    </Container>
  </Page>
}

export default Dashboard;