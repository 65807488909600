import { useContext, useEffect, useState } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import ApplicationContext from './context';
import ChangePassword from './pages/ChangePassword';
import Dashboard from './pages/Dashboard';
import DashboardLayout from './pages/DashboardLayout';
import Login from './pages/Login';
import Page404 from './pages/Page404';
import Sales from './pages/Sales';
import Staffs from './pages/Staffs';

// ----------------------------------------------------------------------
export default function Router() {
  const { auth } = useContext(ApplicationContext);
  const [routes, setRoutes] = useState({});
  useEffect(() => {
    setRoutes({
      path: '/',
      element: <DashboardLayout />,
      children: auth ? [
        { path: '/', element: <Dashboard /> },
        { path: '/profile', element: <ChangePassword /> },
        { path: '/change-password', element: <ChangePassword /> },
        { path: '/sales', element: <Sales /> },
        { path: '/staffs', element: <Staffs /> },
        { path: '/login', element: <Navigate to="/" /> },
        { path: '*', element: <Page404 /> }
      ] : [
        { path: '/', element: <Login /> },
        { path: '*', element: <Login /> }
      ]
    });
  }, [auth])

  return useRoutes([
    routes
  ]);

}
