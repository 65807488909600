// material
import styled from '@emotion/styled';
import {
  AppBar, Badge, BottomNavigation, BottomNavigationAction, Box, Button, Dialog, DialogActions, DialogTitle, Drawer, Fab, Icon, IconButton, List, ListItemButton, ListItemIcon, ListItemText,
  Paper, Slide, Stack, Toolbar, Tooltip, Zoom
} from '@mui/material';
import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { MotionContainer } from '../components/animate';
import ScrollTop from '../components/ScrollTop';
import ApplicationContext from '../context';
import LogoText from './LogoText';

const pages = [
  { title: 'Dashboard', path: '', icon: 'dashboard', },
  { title: 'Sales', path: 'sales', icon: 'trending_up', },
  { title: 'Staffs', path: 'staffs', icon: 'people', },
  { title: 'Profile', path: 'profile', icon: 'person', },
];

const drawerWidth = 240;
const openedMixin = (theme) => ({
  backgroundColor: '#00000000',
  border: 'none',
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  backgroundColor: '#00000000',
  border: 'none',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const MuiAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

}));

const MuiDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    backgroundColor: '#00000000',
    width: drawerWidth,
    flexShrink: 0,
    zIndex: theme.zIndex.drawer - 200,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    border: 'none',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export const Circle = styled(Box)(({
  background = '#676DFF',
  ...other
}) => ({
  zIndex: -10,
  position: 'absolute',
  background,
  mixBlendMode: 'difference',
  ...other
}));

export default function DashboardLayout({ ...props }) {
  const { pathname } = useLocation();
  useLayoutEffect(() => {
    const anchor = document.querySelector(
      '#back-to-top-anchor',
    );
    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [pathname])
  const activePage = window.location.pathname.split('/')[1];
  const [open, setOpen] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  const { auth, users, logout, menus } = useContext(ApplicationContext);
  const [close, setClose] = useState(false);
  const handelLogout = (toggle) => () => {
    setClose(toggle);
  }
  useEffect(() => {
    const handelRightClick = (event) => {
      event.preventDefault();
    }
    document.addEventListener('contextmenu', handelRightClick);
    return () => {
      document.removeEventListener('contextmenu', handelRightClick);
    };
  }, [])
  return (
    <MotionContainer initial="initial" open >
      <Box sx={{ overflowX: 'hidden', display: 'flex', }}>
        <MuiAppBar position="fixed" open={open} >
          <Toolbar>
            {auth && <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: '36px',
                display: { xs: 'none', md: 'flex' },
                transition: 'all 700ms'
              }}
            >
              <Zoom in={true}><Icon>{open ? 'close' : 'menu'}</Icon></Zoom>
            </IconButton>}
            <Link to='/'>
              <LogoText height={25} />
            </Link>
            <Box flexGrow={1} />
            {menus}
            {auth && <IconButton onClick={handelLogout(true)} sx={{ display: { xs: 'flex', md: 'none' }, }}>
              <Tooltip title="Logout" placement="bottom">
                <Icon>power_settings_new</Icon>
              </Tooltip>
            </IconButton>}
          </Toolbar>
        </MuiAppBar>
        {auth && <MuiDrawer variant="permanent" open={open} sx={{ display: { xs: 'none', md: 'flex' }, }} >
          <Toolbar />
          <List>
            {pages.map(({ title, icon, path }) => (
              <ListItemButton key={title} component={Link} to={`/${path}`}
                selected={path === (activePage)}
                sx={{
                  borderStartEndRadius: '20px',
                  borderBottomRightRadius: '20px',
                }}
              >
                <Tooltip placement='right-end' arrow title={title}>
                  <ListItemIcon>
                    <Badge color='primary'
                      {...path === 'staffs' && { badgeContent: users?.length }}>
                      <Icon>{icon}</Icon>
                    </Badge>
                  </ListItemIcon>
                </Tooltip>
                <Slide direction='right' in={open}>
                  <ListItemText primary={title} />
                </Slide>
              </ListItemButton>
            ))}
            <ListItemButton
              sx={{
                borderStartEndRadius: '20px',
                borderBottomRightRadius: '20px',
              }}
              onClick={handelLogout(true)}            >
              <Tooltip placement='right-end' arrow title={'Logout'}>
                <ListItemIcon>
                  <Icon>power_settings_new</Icon>
                </ListItemIcon>
              </Tooltip>
              <Slide direction='right' in={open}>
                <ListItemText primary={'Logout'} />
              </Slide>
            </ListItemButton>
          </List>
        </MuiDrawer>}
        <Stack width='100%' mb={2}>
          <Toolbar id='back-to-top-anchor' />
          <Outlet />
          <Toolbar sx={{ display: { xs: 'flex', md: 'none' }, }} />
        </Stack>
        <ScrollTop>
          <Fab color="secondary" size="small" aria-label="scroll back to top">
            <Icon>keyboard_arrow_up</Icon>
          </Fab>
        </ScrollTop>
        <Circle sx={{
          width: 300,
          height: 300,
          left: -100,
          top: -100,
          opacity: .45,
          filter: 'blur(194px)',
        }} />
        {auth && <Paper sx={{
          position: 'fixed', bottom: 0, left: 0, right: 0,
          display: { xs: 'flex', md: 'none' },
        }} elevation={3}>
          <BottomNavigation
            width='100%'
            sx={{ width: '100%' }}
            showLabels
            value={pages.findIndex(({ path }) => path === activePage)}
          >
            {pages.map(({ title, icon, path }) => (<BottomNavigationAction component={Link} to={`/${path}`} label={title} icon={<Icon>{icon}</Icon>} />))}
          </BottomNavigation>
        </Paper>}
        <Dialog
          open={close}
          onClose={handelLogout(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Do you want to 'Logout'?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handelLogout(false)}>Cancel</Button>
            <Button onClick={e => { logout(); handelLogout(false)() }} autoFocus>
              Logout
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </MotionContainer>
  );
}