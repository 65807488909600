import {
  Box, Button, Card, CardActionArea, CardActions, CardContent, CardHeader, Container, Fab,
  Icon, IconButton, Slide, Toolbar
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import * as Yup from 'yup';
import CustomSwipeableDrawer from "../components/CustomSwipeableDrawer";
import Page from "../components/Page";
import ApplicationContext from "../context";
import { cteateUser } from "../context/APIRequest";
import { CustomField } from "./Sales";

const Staffs = () => {
  const { users, setLoading, setMenus } = useContext(ApplicationContext);
  const [value, setValue] = useState(0);
  const [department, setDepartment] = useState([]);
  const [open, setOpen] = useState(false);
  const [starting, setStarting] = useState(true);
  useEffect(() => {
    if (!starting) {
      const department = new Set(['All', ...users.map(item => item.department)]);
      setDepartment(Array.from(department));
    }
  }, [users, starting])
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const loadingRef = useRef(setLoading);
  const menu = useRef(setMenus);

  const childrens = <>
    <Button color="primary" variant="outlined" size="small"
      sx={{
        px: 4, py: 1, lineHeight: 1,
        display: { xs: 'none', md: 'flex' },
      }}
      onClick={e => setOpen(true)}
      startIcon={<Icon>add</Icon>}>
      New User
    </Button>
  </>
  const child = useRef(childrens);

  useLayoutEffect(() => {
    menu.current(child.current)
    loadingRef.current(true)
    setTimeout(() => {
      setStarting(false);
      loadingRef.current(false)
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => { menu.current(<></>) }
  }, [])

  return <Page title={'User List'} flexGrow={1} mt={3}>
    <Container maxWidth="xl">
      <Toolbar>
        <Box gap={1} display={'grid'} flexGrow={1} gridTemplateColumns={'repeat(auto-fill, minmax(80px,1fr))'}>
          {
            department.map((item, index) => <Button
              key={`department-${index}`}
              variant={value === index ? 'outlined' : 'standard'}
              onClick={e => setValue(index)}>{item}</Button>)
          }
        </Box>
      </Toolbar>
      <Box gap={2} display={'grid'} gridTemplateColumns={'repeat(auto-fill, minmax(300px,1fr)) '}>
        {!starting &&
          users.filter(item => value === 0 ? true : item.department === department[value])
            .map((item, index) => {
              return (
                <UserView {...item} index={index} />
              )
            })
        }
      </Box>
      <Fab color="primary" size="small" aria-label="add" onClick={toggleDrawer(true)}
        sx={{
          position: 'fixed',
          bottom: { xs: 76, md: 16 },
          right: '1rem',
          display: { xs: 'flex', md: 'none' },
        }}>
        <Icon>add</Icon>
      </Fab>
    </Container>
    <AddUser {...{ open, setOpen, toggleDrawer, department }} />
  </Page>
}


const newUserFields = (department) => [
  { label: 'First Name', name: 'firstName', },
  { label: 'Last Name', name: 'lastName', },
  { label: 'Email', name: 'email', },
  { label: 'Username', name: 'username', },
  { label: 'Password', name: 'password', type: 'password' },
  { label: 'Department', name: 'department', list: 'department', options: department?.slice(1) || [] },
  {
    label: 'Role', name: 'userRole', type: 'select', options: [
      { value: 'ROLE_USER', label: 'User' },
      { value: 'ROLE_MANAGER', label: 'Manager' },
      { value: 'ROLE_ADMIN', label: 'Admin' },
    ]
  },
  { label: 'Country Code', name: 'countryCode', type: 'select', options: ['au', 'us',] },
  {
    label: 'User Status', name: 'userStatus', type: 'radio', options: [
      { value: 'ACTIVE', label: 'Active' },
      { value: 'INACTIVE', label: 'Inactive' },
    ]
  },
]

const AddUser = ({ open, setOpen, toggleDrawer, department }) => {
  const { setLoading, callAPI, getUsers } = useContext(ApplicationContext);
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      userStatus: 'ACTIVE',
      userRole: 'ROLE_USER',
      countryCode: 'au',
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required('First name is required'),
      lastName: Yup.string().required('Last name is required'),
      email: Yup.string().email('Email is invalid').required('Email is required'),
      phoneNumber: Yup.string().required('Phone number is required'),
      address: Yup.string().required('Address is required'),
      userStatus: Yup.string().required('User status is required'),
      userRole: Yup.string().required('User role is required'),
      countryCode: Yup.string().required('Country code is required'),
    }),
    onSubmit: async (data) => {
      setLoading(true)
      setOpen(false);
      const [, error] = await callAPI(cteateUser, data)
      if (!error) {
        formik.resetForm();
      }
      getUsers(true);
    }
  });

  const { handleSubmit } = formik;
  return (
    <FormikProvider value={formik}>
      <CustomSwipeableDrawer
        onSubmit={handleSubmit}
        noValidate
        component={Form}
        title={'New User'}
        toggleDrawer={toggleDrawer}
        open={open}
        actions={<>
          <Button>Save</Button>
        </>}>
        <Box gap={2} display={'grid'} gridTemplateColumns={{ xs: '1fr', sm: '1fr 1fr' }}>
          {
            newUserFields(department).map((item, index) => <CustomField key={`new-user-${item.name}`} {...item} />
            )
          }
        </Box>
      </CustomSwipeableDrawer>
    </FormikProvider>
  )
}
export default Staffs;

const UserView = ({ id,
  department,
  email,
  firstName,
  lastName,
  userRole,
  username,
  countryCode,
  userStatus,
  index,
  ...props
}) => {
  const { updateUser } = useContext(ApplicationContext);
  return (
    <Slide in direction="up" key={`user-${id}`} {...props} style={{ transitionDelay: `${50 * index}ms` }}>
      <Card sx={{ height: '100%' }} >
        <CardActionArea sx={{ height: '100%' }}>
          <CardHeader title={`${id}. ${firstName} ${lastName}`} subheader={department}
            action={<>
              <IconButton size="small">
                <Icon size="small">edit</Icon>
              </IconButton>
            </>}
          />
          <CardContent sx={{ flexGrow: 1, }}>
            Username : {username}
          </CardContent>
          <CardActions>

            <Button onClick={e => updateUser({
              id,
              department,
              email,
              firstName,
              lastName,
              password: '',
              userRole,
              username,
              userStatus: userStatus !== 'INACTIVE' ? 'INACTIVE' : 'ACTIVE',
              countryCode
            })}>{userStatus === 'ACTIVE' ? 'Remove' : 'Active'}</Button>
          </CardActions>
        </CardActionArea>
      </Card>
    </Slide>
  )
}

