import { Box, CardActions, CardContent, Icon, IconButton, Paper, Stack, SwipeableDrawer, Toolbar, Typography } from "@mui/material"
import { grey } from "@mui/material/colors";
import { styled } from "@mui/system";
const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));
const CustomSwipeableDrawer = ({ children, actions, toggleDrawer, open, title, ...props }) => {
  return (
    <SwipeableDrawer
      container={document.body}
      anchor="bottom"
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      disableSwipeToOpen={false}
      ModalProps={{
        keepMounted: true,
      }}
      {...props}
    >
      <Stack height='100%'>
        <Paper>
          <Puller />
          <Toolbar>
            <Typography sx={{ p: 2, color: 'text.secondary', flexGrow: 1, }}>{title}</Typography>
            <IconButton onClick={toggleDrawer(false)}>
              <Icon>close</Icon>
            </IconButton>
          </Toolbar>
        </Paper>
        {actions ?
          <>
            <CardContent sx={{ flexGrow: { xs: 1, md: 0 }, overflow: 'auto' }}>
              {children}
            </CardContent>
            <CardActions sx={{ alignSelf: 'flex-end' }}>
              {actions}
            </CardActions>
          </> : children
        }
      </Stack>
    </SwipeableDrawer>
  )
}
export default CustomSwipeableDrawer