import { Alert, Snackbar } from "@mui/material";
import { createContext, useEffect, useRef, useState } from "react";
import Loadder from "../components/Loadder";
import ThemeConfig from "../theme";
import { getAllClients, getAllUsers, getClientById, getUser, getUserById, loginAPI, updateUserById } from "./APIRequest";

const ApplicationContext = createContext(null);
export default ApplicationContext;
export function ContextProvider({ children }) {
  const [loading, setLoading] = useState();
  const [alert, setAlert] = useState();
  const [user, setUser] = useState();
  const [auth, setAuth] = useState(localStorage.getItem("token"));
  const [sales, setSales] = useState([]);
  const [menus, setMenus] = useState(<></>);
  const handelError = (result, status) => {
    if (status === 403) {
      setAuth(null)
      setUser(null)
    }
    setAlert({ open: true, type: 'error', message: result.data })
  }
  const callAPI = async (API, data, status = [422, 404]) => {
    const error = [null, 'Network Error!!!'];
    try {
      const fetchResult = await API(data);
      const result = await fetchResult.json();
      if (result.status) {
        error[0] = result.data;
        delete error[1]
      } else if (!status.includes(fetchResult.status)) {
        handelError(result);
      } else {
        error[1] = result.data || result.errorCode;
      }
    } catch (error) {
      handelError({ data: 'Network Error!!!' });
    }
    return error;

  }
  const callAPIRef = useRef(callAPI);
  const { open = false, type, message } = alert || {};
  const [users, setUsers] = useState([]);
  const getUsers = async (b) => {
    setLoading(b)
    const [result, error] = await callAPIRef.current(getAllUsers);
    if (!error) {
      const result1 = await Promise.all(result.map(async (user) => {
        const _result = localStorage.getItem(`user-${user.id}`);
        if (_result) {
          const _user = JSON.parse(_result);
          if (_user?.updatedDate === user?.updatedDate) {
            return _user;
          }
        }
        const [userResult, userError] = await callAPIRef.current(getUserById, { id: user.id });
        if (!userError) {
          localStorage.setItem(`user-${user.id}`, JSON.stringify(userResult));
          return userResult;
        }
      }));
      setUsers(result1.sort((a, b) => a.firstName.localeCompare(b.firstName)));
    }
    setLoading(false)
  }
  const getSales = async (b) => {
    setLoading(b);
    const [result, error] = await callAPIRef.current(getAllClients);
    if (!error) {
      const result1 = await Promise.all(result.map(async (client) => {
        const _result = localStorage.getItem(`client-${client.id}`);
        if (_result) {
          const _client = JSON.parse(_result);
          if (_client?.updatedDate === client?.updatedDate) {
            return _client;
          }
        }
        const [clientResult, clientError] = await callAPIRef.current(getClientById, { id: client.id });
        if (!clientError) {
          localStorage.setItem(`client-${client.id}`, JSON.stringify(clientResult));
          return clientResult;
        }
      }));
      setSales(result1)
    }
    setLoading(false);
  }
  useEffect(() => {
    if (auth) {
      setLoading(true);
      const fetchUser = async () => {
        const [result, error] = await callAPIRef.current(getUser);
        if (!error) {
          setLoading(false);
          setUser(result);
          getUsers();
          getSales();
          return
        }
        setAuth()
        localStorage.removeItem('token')
        setLoading(false);
        setAlert({ open: true, type: 'error', message: 'You are not authorized to access this page' })
      }
      fetchUser();
    }
  }, [auth]);
  const handleClose = _ => setAlert();
  const logout = async () => {
    setAuth(null)
    localStorage.removeItem('token')
    setUser(null)
  }
  const login = async (data) => {
    setLoading(true);
    const [result, error] = await callAPIRef.current(loginAPI, data);
    if (!error) {
      localStorage.setItem('token', result)
      setAuth(result)
      setAlert({ open: true, type: 'success', message: 'Wecome To CRM' })
    }
    setLoading(false);
  }
  const updateUser = async (data) => {
    const [, error] = await callAPI(updateUserById, data);
    if (!error) {
      const list = [...users]
      const index = list.findIndex(item => item.id === data.id);

      const [result, e] = await callAPI(getUserById, data);
      if (!e) {
        localStorage.setItem(`user-${user.id}`, JSON.stringify(result));
        list[index] = result;
      }
      setUsers(list)
      localStorage.removeItem(`user-${data.id}`);
      getUsers();
    }
  }
  return (
    <ApplicationContext.Provider value={{
      setAlert, auth, user, callAPI, setAuth, setLoading, logout, users, sales, updateUser,
      login, getSales, menus, setMenus, getUsers
    }}>
      <ThemeConfig>
        {children}
        {loading && <Loadder />}
        <Snackbar open={open}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          autoHideDuration={3000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={type}>{message}</Alert>
        </Snackbar>
      </ThemeConfig>
    </ApplicationContext.Provider>
  )
}