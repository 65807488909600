import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardHeader, Checkbox, Container, Fab, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Icon, IconButton, InputAdornment, InputLabel, MenuItem, Radio, RadioGroup, Select, Slide, Stack, TextField, Typography } from "@mui/material";
import { Form, FormikProvider, useFormik, useFormikContext } from "formik";
import { useContext, useLayoutEffect, useRef, useState } from "react";
import * as Yup from 'yup';
import CustomSwipeableDrawer from "../components/CustomSwipeableDrawer";
import Page from "../components/Page";
import ApplicationContext from "../context";
import { saveClient } from "../context/APIRequest";

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  address: '',
  company: '',
  websiteType: 'Static',
  domain: '',
  status: 'Active',
  includes: {
    website: true,
    domain: true,
    hosting: true,
  },
  saleUser: '',
  closeUser: '',
  unique: 'Unique'
}
const Sales = ({ children, ...props }) => {
  const { users, sales, setLoading, callAPI, getSales, setMenus } = useContext(ApplicationContext);
  const loadingRef = useRef(setLoading);
  const menu = useRef(setMenus);
  const [starting, setStarting] = useState(true);

  const childrens = <>
    <Button color="primary" variant="outlined" size="small"
      sx={{
        px: 4, py: 1, lineHeight: 1,

      }}
      onClick={e => setOpen(true)}
      startIcon={<Icon>add</Icon>}>
      New Sale
    </Button>
  </>
  const child = useRef(childrens);

  useLayoutEffect(() => {
    menu.current(child.current)
    loadingRef.current(true)
    setTimeout(() => {
      setStarting(false);
      loadingRef.current(false)
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => { menu.current(<></>) }
  }, [])
  const [open, setOpen] = useState(false);
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required('First name is required'),
      // lastName: Yup.string().required('Last name is required'),
      // email: Yup.string().email('Email is invalid').required('Email is required'),
      // phoneNumber: Yup.string().required('Phone number is required'),
      // address: Yup.string().required('Address is required'),
      company: Yup.string().required('Company name is required'),
      domain: Yup.string().required('Domain is required'),
      websiteType: Yup.string().required('Website is required'),
      includes: Yup.object().shape({
        hosting: Yup.boolean(),
      }),
      designingCost: Yup.number().required('Designing cost is required'),
      renewalCost: Yup.number().required('Renewing cost is required'),
      registeredDate: Yup.date(),
      renewalDate: Yup.date(),
      seo: Yup.object().when('includes', (includes) => {
        return includes.seo ? Yup.object().shape({
          price: Yup.number().required('SEO price is required'),
          note: Yup.string(),
        }) : Yup.object().shape({
          price: Yup.number(),
          note: Yup.string(),
        })
      }),
      saleUser: Yup.number().required('Sale user is required'),
      closeUser: Yup.number().required('Close user is required'),
    }),
    onSubmit: async (data) => {
      setLoading(true)
      setOpen(false);
      const [, error] = await callAPI(saveClient, data)
      if (!error) {
        formik.resetForm();
      }
      getSales(true);
    }
  });

  const { values, handleSubmit } = formik;

  return <Page title={'Sales'} flexGrow={1} mt={3}>
    <Container maxWidth="xl">
      <Fab color="primary" size="small" aria-label="add" onClick={toggleDrawer(true)}
        sx={{
          position: 'fixed',
          bottom: { xs: 76, md: 16 },
          right: '1rem',
          display: { xs: 'flex', md: 'none' },
        }}>
        <Icon>add</Icon>
      </Fab>
      <Box gap={2} display={'grid'} gridTemplateColumns={'repeat(auto-fill, minmax(250px,1fr)) '}>
        {!starting &&
          sales.map(({ id, company, domain, ...data }, index) => {
            return (
              <Slide in direction="up" key={`user-${id}`} style={{ transitionDelay: `${50 * index}ms` }} >
                <Card sx={{ height: '100%' }}>
                  <CardActionArea sx={{ height: '100%' }}>
                    <CardHeader title={`${company}`} subheader={<a href={`${domain.startsWith('http') ? '' : 'https://'}${domain}`} target='_blank' rel="noreferrer" >{domain}</a>} />
                    <CardContent sx={{ flexGrow: 1, }} component={Stack} spacing={1}>
                      {
                        showFields.map(({ title, getValue }) => {
                          return (
                            <Stack direction={'row'}>
                              <Typography variant='caption' color='warning.dark' flexGrow={1}>{title}</Typography>
                              {getValue(data)}
                            </Stack>
                          )
                        })
                      }
                    </CardContent>
                    <CardActions>
                      <Button>Edit</Button>
                    </CardActions>
                  </CardActionArea>
                </Card>
              </Slide>
            )
          })
        }
      </Box>
    </Container>
    <FormikProvider value={formik}>
      <CustomSwipeableDrawer
        onSubmit={handleSubmit}
        noValidate
        component={Form}
        title={'New Sale'}
        toggleDrawer={toggleDrawer}
        open={open}
        actions={<>
          <Button variant="contained" type='reset' onClick={e => { formik.resetForm(initialValues) }}>Reset</Button>
          <Button variant="contained" type='submit'>Save</Button>
        </>}>
        <Box gap={2} display={'grid'} gridTemplateColumns={{ xs: '1fr', sm: '1fr 1fr', md: '1fr 1fr 1fr' }}>
          {!starting &&
            newUserFields({ users: users.filter(({ department }) => department === 'Sales').map(({ id, firstName, lastName }) => ({ value: id, label: `${firstName} ${lastName}` })) }).map(({ title, children, ...item }, index) => {

              if (item.condition && values[item.condition]) {
                switch (typeof values[item.condition]) {
                  case 'string':
                    if (values[item.condition] === item.conditionValue) {
                      return <></>
                    }
                    break;
                  case 'object':
                    if (!values[item.condition]?.[item.conditionValue]) {
                      return <></>
                    }
                    break;
                  default:
                }
              }
              if (title)
                return <Slide in
                  direction="up" style={{ transitionDelay: `${index * 50}ms` }}
                  key={`new-user-${title}`}>
                  <Card>
                    <CardHeader title={title} />
                    <CardContent component={Stack} spacing={2}>
                      {
                        children.map((child, index) => {
                          return <CustomField {...child} key={`${title}-index`} />
                        })
                      }
                    </CardContent>
                  </Card>
                </Slide>
              return <CustomField {...item} />
            })
          }
        </Box>
      </CustomSwipeableDrawer>
    </FormikProvider>
  </Page>
}

const showFields = [
  { title: 'Owner Name', getValue: (item) => <Typography flexGrow={1} variant='caption' textAlign='right'> {item.firstName} ${item.lastName}</Typography>, },
  { title: 'Pnone Number', getValue: (item) => <Typography component={'a'} href={`tel:${item.phoneNumber}`} flexGrow={1} variant='caption' textAlign='right'>{item.phoneNumber}</Typography>, },
  { title: 'Email', getValue: (item) => <Typography flexGrow={1} component={'a'} href={`mailto:${item.email}`} variant='caption' textAlign='right'>{item.email}</Typography>, },
  { title: 'Address', getValue: (item) => <Typography flexGrow={1} variant='caption' textAlign='right'>{item.address}</Typography>, },
  {
    title: 'Services', getValue: (item) => <Stack flexGrow={1}   >
      {
        Object.keys(item.includes).filter(key => item.includes[key]).map(key => {
          return <Typography key={key} flexGrow={1} variant='caption' textAlign='right'>{key}</Typography>
        })
      }
    </Stack>,
  },
]

export const CustomField = ({ list, type, options = [], condition, conditionValue, ...item }) => {
  const { errors, values, getFieldProps, setFieldValue, touched } = useFormikContext();
  const [showPassword, setShowPassword] = useState(false);
  if (type === 'select') {
    return (
      <FormControl size='small' fullWidth error={errors[item.name]}>
        <InputLabel>{item.label}</InputLabel>
        <Select
          value={values[item.name]}
          label={item.label}
          sx={{ zIndex: 1 }}
          onChange={(e) => setFieldValue(item.name, e.target.value)}
        >
          {item.placeholder && <MenuItem disabled value="">
            <em>{item.placeholder}</em>
          </MenuItem>}
          {
            options.map((option, index) => {
              const { value, label } = (typeof option === 'string') ? { value: option, label: option } : option;
              return <MenuItem key={index} value={value}>{label}</MenuItem>
            })
          }
        </Select>
        <FormHelperText>{errors[item.name]}</FormHelperText>
      </FormControl>
    )
  }

  if (type === 'radio') {
    return (
      <FormControl size="small"
        {...getFieldProps(item.name)}
        value={values[item.name]}
      >
        <FormLabel id={item.name}>{item.label}</FormLabel>
        <RadioGroup
          row
          aria-labelledby={item.name}
          name={item.name}
          value={values[item.name]}
        >
          {
            options.map(option => {
              const { value, label } = (typeof option === 'string') ? { value: option, label: option } : option;
              return <FormControlLabel value={value} control={<Radio />} label={label} />
            })
          }
        </RadioGroup>
      </FormControl>
    )
  }
  if (type === 'checkbox') {
    return (
      <FormControl
        required
        size="small"
        component="fieldset"
        variant="outlined"
      >
        <FormLabel component="legend">{item.label}</FormLabel>
        <FormGroup row>
          {
            options.map(({ value, label }) => <FormControlLabel
              control={
                <Checkbox defaultChecked={values.includes?.[value]} onChange={e => setFieldValue(e.target.name, e.target.checked)} name={`${item.name}.${value}`} />
              }
              label={label}
            />)
          }
        </FormGroup>
      </FormControl>
    )
  }
  return <div> <TextField fullWidth size="small" {...item} {...list && {
    inputProps: {
      list
    }
  }}
    autoComplete="new-password"
    error={errors[item.name]?.length > 0 && touched[item.name]}
    helperText={errors[item.name]?.length > 0 && touched[item.name] && errors[item.name]}
    {...getFieldProps(item.name)}
    type={type}
    value={values[item.name]}
    {...type === 'password' && {
      type: showPassword ? 'text' : 'password',
      InputProps: {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton edge="end" tabIndex={-1} onClick={e => setShowPassword(!showPassword)}>
              <Icon>{showPassword ? 'visibility_on' : 'visibility_off'}</Icon>
            </IconButton>
          </InputAdornment>
        )
      }
    }} />
    {list &&
      <datalist id={list}>
        {options?.map((item, index) => <option key={`${item.name}-${index}`} value={item} />)}
      </datalist>
    }
  </div>
}


const newUserFields = ({ users }) => [
  {
    title: 'Company Detail',
    children: [
      { label: 'Company Name', name: 'company', placeholder: 'Enter Company Name' },
      { label: 'Domain', name: 'domain', placeholder: 'Enter Site Url' },
    ]
  },
  {
    title: 'Contact Detail',
    children: [
      { label: 'First Name', name: 'firstName', placeholder: 'Enter First Name' },
      { label: 'Last Name', name: 'lastName', placeholder: 'Enter Last Name' },
      { label: 'Email', name: 'email', placeholder: 'Enter Email' },
      { label: 'Phone Number', name: 'phoneNumber', placeholder: 'Enter Phone Number' },
      { label: 'Address', name: 'address', placeholder: 'Enter Address' },
    ]
  },
  {
    title: 'Service Detail',
    children: [
      {
        label: 'Service Included', name: 'includes', type: 'checkbox',
        options: [
          { label: 'Website', value: 'website' },
          { label: 'Domain', value: 'domain' },
          { label: 'Hosting', value: 'hosting' },
          { label: 'Logo', value: 'logo' },
          { label: 'SEO', value: 'seo' },
          { label: 'Business Card', value: 'businessCard' },
          { label: 'Flyer', value: 'flyer' },
          { label: 'Invoice', value: 'invoice' },
          { label: 'Quotation', value: 'quotation' },
          { label: 'Support', value: 'support' },
          { label: 'Gsuite', value: 'gsuite' },
          { label: 'Letterhead', value: 'letterhead' },
        ]
      },
      { label: 'Website Type', name: 'websiteType', type: 'radio', options: ['Static', 'Word Press', 'E-Commerce'] },
      { label: 'Notes', name: 'notes', multiline: true, rows: 4, placeholder: 'Enter Notes' },
    ]
  },
  {
    title: 'Cpanel Detail',
    condition: 'includes',
    conditionValue: 'hosting',
    children: [
      { label: 'Cpanel Username', name: 'cpanelUsername', },
      { label: 'Cpanel Password', name: 'cpanelPassword', type: 'password' }
    ]
  },
  {
    title: 'WP Detail',
    condition: 'websiteType', conditionValue: 'Static',
    children: [
      { label: 'WP Username', name: 'wpUsername', },
      { label: 'WP Password', name: 'wpPassword', type: 'password', },
    ]
  },
  {
    title: 'Gsuite Detail',
    condition: 'includes',
    conditionValue: 'gsuite',
    children: [
      { label: 'GSute Total User', name: 'gsuite.totalUser', },
      {
        label: 'Gsute Price Per User', name: 'gsuite.pricePerUser', InputProps: {
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }
      },
    ]
  },
  {
    title: 'SEO Detail',
    condition: 'includes',
    conditionValue: 'seo',
    children: [
      {
        label: 'SEO Price Per Month', name: 'seo.price', InputProps: {
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }
      },
      { label: 'SEO Note', name: 'seo.note', multiline: true, rows: 4, placeholder: 'Enter Notes' },
    ]
  },
  {
    title: 'Payment Detail',
    children: [
      {
        label: 'Designing Cost', name: 'designingCost', type: 'number', placeholder: 'Enter Designing Cost',
        InputProps: {
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }
      },
      {
        label: 'Renewal Cost(Yearly)', name: 'renewalCost', type: 'number', placeholder: 'Enter Renewal Cost',
        InputProps: {
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }
      },
      {
        label: 'Registered Date', name: 'registeredDate', type: 'date', placeholder: 'Enter Registered Date',
        InputLabelProps: {
          shrink: true
        }
      },
      {
        label: 'Renewal Date', name: 'renewalDate', type: 'date', placeholder: 'Enter Renewal Date',
        InputLabelProps: {
          shrink: true
        }
      },
    ]
  },
  {
    title: 'Status',
    children: [
      { label: 'Sale By', name: 'saleUser', type: 'select', placeholder: 'Select User', options: users },
      { label: 'Close By', name: 'closeUser', type: 'select', placeholder: 'Select User', options: users },
      { label: 'Status', name: 'status', type: 'radio', options: ['Interested', 'Active', 'Inactive'] },
      { label: 'Unique', name: 'unique', type: 'radio', options: ['Unique', 'Copy'] },
    ]
  }

]
export default Sales;